import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ResponsePageable } from 'app/shared/models/responsePageable.model';
import { DocumentResult } from 'app/shared/models/documentResult.model';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { ResultsService } from 'app/shared/api/results.service';
import { StartQualificationEvent } from 'app/shared/api/start-qualification-event.service';
import { ServiceStatusService } from 'app/shared/api/service-status.service';
import { environment } from 'environments/environment';
import { DownloadService } from 'app/shared/api/download.service';

@Component({
  selector: 'app-results-log-dialog',
  templateUrl: './results-log-dialog.component.html',
  styleUrls: ['./results-log-dialog.component.scss'],
})
export class ResultsLogDialogComponent implements OnInit {
  docsFilesBaseUrl = environment.docsFilesBaseUrl;
  public documentsResult: DocumentResult[] = [];
  orderId: string;
  timeLeft: number = 10;
  interval;
  isIndeterminationMode: boolean = false;
  indeterminationReason: string = '';

  constructor(
    public dialogRef: MatDialogRef<ResultsLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Object,
    public loader: AppLoaderService,
    private startQualificationEvent: StartQualificationEvent,

    public restStatusService: ServiceStatusService,
    public restResults: ResultsService,
    public restDownload: DownloadService
  ) {
    dialogRef.afterClosed().subscribe((result) => {
      this.pauseTimer();
    });
  }

  ngOnInit() {
    this.documentsResult = this.data['documentosResult'];
    this.orderId = this.data['orderId'];
    this.loadStatusServices();
    this.startTimer();
  }

  openIndeterminationDialog(): void {
    this.isIndeterminationMode = true;
  }

  updateDialog(orderId: string) {
    // this.loader.open();
    this.restResults
      .getAllOrderResults(orderId)
      .subscribe((data: ResponsePageable) => {
        this.documentsResult = data.content;
        this.loadStatusServices();
        // this.loader.closeOne();
      });
  }

  downloadDocument(documentName: string) {
    this.restDownload.downloadDocument(documentName).subscribe(
      (response) => {
        console.log(response);
        var binaryData = [];
        binaryData.push(response.data);
        var url = window.URL.createObjectURL(new Blob(binaryData));
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.setAttribute('target', 'blank');
        a.href = url;
        a.download = response.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.updateDialog(this.orderId);
        this.timeLeft = 10;
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  private loadStatusServices = () => {
    this.restStatusService
      .getGeneralCertificates(0, 100)
      .subscribe((data: ResponsePageable) => {
        data.content.forEach((service, index) => {
          if (service.visaoDocumento == 'CUSTOMIZADO') {
            data.content.splice(index, 1);
          }
        });

        data.content.map((o) => {
          this.documentsResult.map((doc, index) => {
            if (doc.nomeDocumento == o.nomeDocumento) {
              this.documentsResult[index].siglaDocumento = o.siglaDocumento;
              if (o.status == 'IRREGULAR' || o.status == 'INATIVO') {
                this.documentsResult[index].statusServico = o.status;
                this.documentsResult[index].statusObservacoes = o.observacoes;
              }
            }
          });
        });
      });
  };

  fechar(): void {
    let close: boolean = true;
    this.isIndeterminationMode = false;
    this.indeterminationReason = '';
    this.dialogRef.close(close);
  }

  confirmIndetermination(reason): void {
    console.log('Motivo da Indeterminação:', this.indeterminationReason);
    this.restStatusService
      .indeterminateQualification(this.orderId, this.indeterminationReason)
      .subscribe((data: any) => {
        console.log(data);
        this.fechar();
      });
  }
}
